import type { Option } from '../../../../../components/Dropdown/Dropdown';

export const LandingSearchActionType = {
  UpdateSearchInput: 'updateSearchInput',
  UpdateSearchResults: 'updateSearchResults',
  UpdateSelectedOption: 'updateSelectedOption',
} as const;

export type LandingSearchActionType =
  (typeof LandingSearchActionType)[keyof typeof LandingSearchActionType];

type ActionUpdateSearchInput = {
  type: typeof LandingSearchActionType.UpdateSearchInput;
  payload: {
    searchInput: string;
  };
};

type ActionUpdateSearchResults = {
  type: typeof LandingSearchActionType.UpdateSearchResults;
  payload: {
    searchResults: Option[];
  };
};

type ActionUpdateSelectedOption = {
  type: typeof LandingSearchActionType.UpdateSelectedOption;
  payload: {
    selectedOption?: Option;
  };
};

export type LandingSearchReducerAction =
  | ActionUpdateSearchInput
  | ActionUpdateSearchResults
  | ActionUpdateSelectedOption;
