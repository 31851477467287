import type { JSX, JSXElementConstructor, Key } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useIntersectionObserver } from '../../helpers/hooks/useIntersectionObserver';

export type KeyedItemProps = {
  key: Key;
};

export type LazyLoaderV2Props<ItemProps extends KeyedItemProps> = {
  itemsProps: ItemProps[];
  component: JSXElementConstructor<Omit<ItemProps, 'key'>>;
  initialDisplayCount: number;
  overscanDisplayCount: number;
  increment: number;
};

export function LazyLoaderV2<ItemProps extends KeyedItemProps>({
  itemsProps,
  component: Component,
  initialDisplayCount,
  overscanDisplayCount = 0,
  increment,
}: LazyLoaderV2Props<ItemProps>): JSX.Element {
  const [count, setCount] = useState(initialDisplayCount);

  useEffect(() => {
    setCount(initialDisplayCount + overscanDisplayCount);
  }, [initialDisplayCount, overscanDisplayCount]);

  const onIntersect = useCallback(() => {
    setCount((value) => value + increment);
  }, [increment]);

  const { refCallback } = useIntersectionObserver({
    threshold: 0,
    onIntersect,
  });

  return (
    <>
      {itemsProps.slice(0, count).map(({ key, ...rest }, index) => {
        const items: JSX.Element[] = [];

        // Insert pixels detection for intersection observer at good position
        // We need theses pixels because the component can be empty (strate Perso) and has no size (IntersectionObserver don't work correctly if height or width equal 0)
        // We Need 2 pixels detection before and after the component because we don't know in advance the size of the component
        // Theses 2 pixels represents 2 point of the rectangle of component
        if (
          index === count - 1 - overscanDisplayCount &&
          count < itemsProps.length
        ) {
          items.push(
            <div
              key={`sentinel-${key}`}
              style={{
                height: '1px',
                width: '1px',
                margin: '-1px -0 0 -1px',
              }}
              ref={refCallback}
            />,
          );
        }

        items.push(<Component key={key} {...rest} />);

        return items;
      })}
    </>
  );
}
