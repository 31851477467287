export const HeadingSizes = {
  SMALL: 'small',
  MEDIUM: 'medium',
  BIG: 'big',
} as const;

export type HeadingSizes = (typeof HeadingSizes)[keyof typeof HeadingSizes];

export const HeadingLevels = {
  LEVEL_1: 1,
  LEVEL_2: 2,
  LEVEL_3: 3,
  LEVEL_4: 4,
  LEVEL_5: 5,
  LEVEL_6: 6,
} as const;

export type HeadingLevels = (typeof HeadingLevels)[keyof typeof HeadingLevels];

export type HeadingProps = {
  text?: string;
  size?: HeadingSizes;
  level?: HeadingLevels;
  isBig?: boolean;
  isFromDetail?: boolean;
  isFromShowcase?: boolean;
  isFromCreativeMedia?: boolean;
};

export type HeadingHTMLTags = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
